<template>
  <div class="love-to_be">
    <img src="../img/love_to_be.jpg" alt="" class="img" />
    <div class="text">
      <div class="text_title">臻爱倍至</div>
      <p>
        臻爱倍至是护联网旗下的专业护理服务机构，可为社区院内、院外康复患者以及母婴、老年群体提供专业的、有品质的护理服务，目前在北京、青岛已建成臻爱倍至护理站和臻爱倍至诊所。
      </p>
      <p>
        臻爱倍至作为暖小加、护士加的实体依托，在慢病管理、老年病管理、社区养老、妇幼保健、居家康复等多个服务领域积累了丰富的服务经验，目前可开展居家护理、站内护理、线上护理等专业护理服务。
      </p>
      <p>
        臻爱倍至致力于让更多的家庭和人民享受有温度的专业服务。
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.love-to_be {
  display: flex;
  align-items: center;
  margin-top: 84px;
  .img {
    width: 674px;
    margin-right: 53px;
    height: 369px;
    flex-shrink: 0;
  }
  .text {
    width: 480px;
    .text_title {
      font-size: 32px;
      font-family: Source Han Sans CN;
      color: rgba(34, 34, 34, 1);
      margin-bottom: 23px;
    }
    p {
      margin: 0;
      padding: 0;
      margin-bottom: 23px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      color: rgba(85, 85, 85, 1);
      line-height: 26px;
    }
  }
}
</style>
