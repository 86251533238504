<script>
import { Carousel, CarouselItem,Image as ELImg,Dialog } from "element-ui"
export default {
  name: "HomeCarousel",
  components:{ Carousel, CarouselItem, ELImg,Dialog},
  data() {
    return {
      dialogVisible:false,
      mp4Url:null
    }
  },
  methods:{
    gzhwz(){
      window.open("https://mp.weixin.qq.com/s/qXEW1F_SuriXKKkajMA6Dg")
    },
    playMp4(index){
      if(index===1){
        this.mp4Url="https://jkhh-release-video.oss-cn-beijing.aliyuncs.com/operate/mini_h5_screen/7e920e62a29a497ba28f48c908228598.mp4"
      }else if(index===2){
        this.mp4Url="https://jkhh-release-video.oss-cn-beijing.aliyuncs.com/operate/mini_h5_screen/9101beb595c54e1abbae80d18065031c.mp4"
      }
      this.dialogVisible=true
    }
  }
};
</script>

<template>
  <div>
    <Carousel height="80vh">
      <CarouselItem @click.native="gzhwz">
        <ELImg  fit="cover" style="width: 100%; height: 80vh;cursor: pointer" :src="require('./carouse/1.jpg')"/>
      </CarouselItem>
      <CarouselItem @click.native="playMp4(1)">
        <ELImg fit="cover" style="width: 100%; height: 80vh;cursor: pointer" :src="require('./carouse/2.jpg')"/>
      </CarouselItem>
      <CarouselItem @click.native="playMp4(2)">
        <ELImg fit="cover" style="width: 100%; height: 80vh;cursor: pointer" :src="require('./carouse/3.jpg')"/>
      </CarouselItem>
    </Carousel>
    <Dialog append-to-body :visible.sync="dialogVisible">
      <video v-if="dialogVisible" :src="mp4Url" controls="controls" style="width: 100%;height: 100%;"></video>
    </Dialog>
  </div>

</template>

<style scoped lang="scss">

</style>