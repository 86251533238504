<template>
  <div class="nurse_link">
    <img src="../img/iphone.jpg" alt="" class="img" />
    <div class="right">
      <img src="../img/nurse_logo.png" alt="" class="logo" />
      <div class="nurse_title">专属护士的职业发展平台</div>
      <p class="text">
        深耕护士培训赋能和医院护理管理服务十余年，围绕护士职业成长路径构建“全职业生命周期的教育课程体系”，覆盖6大教育方向、30+产品系列，致力于成长为国内权威、可靠的护士职业培训平台
      </p>
      <div class="down">
        <div class="down_content">
          <div>
            <a
              class="app"
              href="https://apps.apple.com/cn/app/hu-shi-jia/id976899984"
              target="_blank"
            >
              <img src="../img/ios.png" alt="" />
              <span>iPhone</span>
            </a>
            <i class="el-icon-download"></i>
          </div>
          <div>
            <a
              class="app"
              href="https://sj.qq.com/myapp/detail.htm?apkName=com.jkhh.nurse"
              target="_blank"
            >
              <img src="../img/andr.png" alt="" />
              <span>Android</span>
            </a>
            <i class="el-icon-download"></i>
          </div>
        </div>
        <img src="../img/ercode.png" class="code" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.nurse_link {
  display: flex;
  margin-top: 84px;
  justify-content: center;
  align-items: center;
  .img {
    width: 578px;
    object-fit: cover;
    height: auto;
  }
  .right {
    margin-left: 83px;
    img {
      width: 118px;
      height: auto;
    }
    .nurse_title {
      font-size: 30px;
      font-family: Source Han Sans CN;
      color: rgba(34, 34, 34, 1);
      margin-top: 29px;
    }
    .text {
      width: 406px;
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-family: Source Han Sans CN;
      color: rgba(136, 136, 136, 1);
      line-height: 26px;
      margin-top: 23px;
    }
    .down {
      display: flex;
      margin-top: 36px;
      align-items: center;
      .down_content {
        div {
          width: 265px;
          height: 68px;
          background: rgba(255, 255, 255, 1);
          border-radius: 10px;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          padding: 0 25px;
          justify-content: space-between;
          transition: box-shadow 0.2s;
          &:hover {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          }
          &:last-child {
            margin-bottom: 0;
          }
          img {
            width: 28px;
            height: auto;
          }
          .app {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #222;
            span {
              margin-left: 21px;
            }
          }
          font-size: 23px;
          font-family: PingFang SC;
          font-weight: 300;

          i {
            color: #999999;
          }
        }
      }
      .code {
        margin-left: 42px;
        width: 163px;
        border-radius: 5px;
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
