<template>
  <div class="back_top" @click="backtop(50)">
    <img src="../../assets/img/top.png" alt="" />
  </div>
</template>
<script>
export default {
  methods: {
    backtop(i) {
      document.documentElement.scrollTop -= i;
      if (document.documentElement.scrollTop > 0) {
        var c = setTimeout(() => this.backtop(i), 10);
      } else {
        clearTimeout(c);
      }
      // window.scroll(0, 0);
    }
  }
};
</script>
<style lang="scss" scoped>
.back_top {
  position: fixed;
  right: 0;
  bottom: 0px;
  width: 58px;
  img {
    width: 100%;
    display: block;
    height: auto;
  }
}
</style>
