<template>
  <div class="index" id="app">
    <Header active="index" />
    <div class="content">
      <HomeCarousel />
      <div class="list person">
        <div class="title">助力护士职业成长，让更多人享受有温度的护理服务</div>
        <div class="line"></div>
        <div class="list_content" ref="person">
          <!-- <div></div> -->
          <div class="li" v-for="item in personList" :key="item.id">
            <div class="li_title">
              <CountNum
                :startVal="item.num"
                :endVal="item.sum"
                :speed="item.time"
                :decimals="0"
                :isReverse="false"
                ref="personNum"
              />
              {{ item.unit }}
            </div>
            <div class="li_line"></div>
            <div class="name">{{ item.name }}</div>
            <img src="./img/personIcon.png" alt="" />
          </div>
        </div>
      </div>
      <div class="list product">
        <div class="title">产品&服务</div>
        <div class="line"></div>
        <div class="list_content">
          <div class="tab">
            <div
              v-for="item in tabList"
              :key="item.id"
              :class="tabCurrent === item.id ? 'active' : ''"
              @click="tabClick(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
          <div>
            <NurseLink v-if="tabCurrent === 1" />
            <SmallWarm v-if="tabCurrent === 2" />
            <LoveToBe v-if="tabCurrent === 3" />
          </div>
        </div>
      </div>
      <div class="list love_fund">
        <div class="data">
          <div class="data_content">
            <div class="data_head">
              <div class="title">赢得用户信赖</div>
              <div class="line"></div>
            </div>
            <div class="ul" ref="loveFund">
              <div
                v-for="(item, index) in loveFundList"
                :key="item.id"
                class="item"
                :class="'li' + index"
              >
                <div class="li_title ">
                  <CountNum
                    :startVal="item.num"
                    :endVal="item.sum"
                    :speed="item.time"
                    :decimals="item.decimals"
                    :isReverse="false"
                    :numDecimals="item.num_decimals"
                    ref="loveFundNum"
                  />{{ item.unit }}
                </div>
                <div class="name">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="more">
          <div class="more_content">
            <div class="logo">
              <img src="./img/logo.png" alt="" />
              护联网与中国社会福利基金会联合发起国内首支以“关爱护士”为主题的公益基金
            </div>
            <div class="btn">
              <a href="https://www.cswef.org/index.php/cswef/welfarepro/detail/id/116.html" target="_blank"
                >了解更多</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="bear_plam">
        <div class="title">获奖荣誉</div>
        <div class="line"></div>
        <div class="list_content">
          <img src="./img/left_btn.png" @click="slideleft" alt="" />
          <div class="center">
            <div
              class="item"
              v-for="(item, index) in sliderList"
              :key="item.id"
              v-show="sliderNum === index"
            >
              <div class="items-desktop">
                <p
                  :ref="'itemP' + index"
                  :id="'itemP' + index"
                  class="active_item"
                >
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
          <img src="./img/right_btn.png" @click="slideRight" alt="" />
        </div>
      </div>
      <!--      <div class="list dynamic">-->
      <!--        <div class="title">最新动态</div>-->
      <!--        <div class="line"></div>-->
      <!--        <div class="list_content">-->
      <!--          <div-->
      <!--            class="li"-->
      <!--            v-for="item in dynamicList"-->
      <!--            :key="item.id"-->
      <!--            @click="dynamicClick(item)"-->
      <!--            :class="dynamicCurrent === item.id ? 'active' : ''"-->
      <!--          >-->
      <!--            {{ item.name }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="list partner">
        <div class="title">合作伙伴</div>
        <div class="line"></div>
        <div class="list_content">
          <img
            :src="item"
            alt=""
            v-for="(item, index) in partnerList"
            :key="index"
          />
        </div>
      </div>
    </div>
    <Footer />
    <BackTop v-if="scrollTop >= 300" />
  </div>
</template>

<script>
import SmallWarm from "./component/smallWarm.vue";
import LoveToBe from "./component/loveToBe";
import NurseLink from "./component/nurseLink";
import BackTop from "@/components/BackTop/Index.vue";
import { isElementInViewport } from "@/utils/common.js";
import CountNum from "@/components/CountNum/Index.vue";
import HomeCarousel from "@/pages/index/component/HomeCarousel.vue";
export default {
  name: "app",
  data: function() {
    return {
      personList: [
        {
          name: "注册用户",
          unit: "",
          sum: 1000000,
          time: (1000000 / 1000) * 10,
          id: 1,
          num: 0
        },
        {
          name: "培训课程分钟数",
          unit: "",
          sum: 30000,
          time: (30000 / 1000) * 10,
          id: 2,
          num: 0
        },
        {
          name: "学员",
          unit: "",
          sum: 450000,
          time: (450000 / 1000) * 10,
          id: 3,
          num: 0
        },
        {
          name: "覆盖医院",
          unit: "",
          sum: 10000,
          time: (10000 / 1000) * 10,
          id: 4,
          num: 0
        },
        {
          name: "深度服务医院",
          unit: "",
          sum: 3000,
          time: (3000 / 1000) * 10,
          id: 5,
          num: 0
        },
        {
          name: "健康产业客户",
          unit: "",
          sum: 5000,
          time: (5000 / 1000) * 10,
          id: 6,
          num: 0
        }
      ],
      loveFundList: [
        {
          name: "累计资助",
          unit: "w",
          sum: 756,
          time: (756 / 1000) * 10,
          id: 1,
          num: 0,
          decimals: 0
        },
        {
          name: "直接救助护士",
          unit: "",
          sum: 1369,
          time: (1369 / 1000) * 10,
          id: 2,
          num: 0,
          decimals: 0
        },
        {
          name: "职业提升帮助",
          unit: "w",
          sum: 32.5,
          time: (32.5 / 1000) * 10,
          id: 3,
          num: 0,
          decimals: 1,
          num_decimals: 1
        },
        {
          name: "全国心理解压站",
          unit: "",
          sum: 175,
          time: (175 / 1000) * 10,
          id: 4,
          num: 0,
          decimals: 0
        },
        {
          name: "合作医院",
          unit: "",
          sum: 1577,
          time: (1577 / 1000) * 10,
          id: 5,
          num: 0,
          decimals: 0
        },
        {
          name: "培养心理疏导师",
          unit: "w",
          sum: 2.3,
          time: (2.3 / 1000) * 10,
          id: 6,
          num: 0,
          decimals: 2,
          num_decimals: 1
        }
      ],
      sliderList: [
        {
          name: "2023-2025年国家卫健委人才交流中心科研合作单位",
          id: 6
        },
        {
          name: "护爱基金官方指定的长期独家合作教育基地",
          id: 5
        },
        {
          name: "2018年荣获“中国大健康产业最具价值项目”",
          id: 4
        },
        {
          name: "2017年荣获“年度中国公益企业”",
          id: 3
        },
        {
          name: "2017年荣获年度中国大健康产业“最具成长力企业",
          id: 2
        },
        {
          name: "2017年荣获行业影响力品牌",
          id: 1
        }
      ],
      tabList: [
        { name: "护士加", id: 1 },
        { name: "暖小加", id: 2 },
        { name: "臻爱倍至", id: 3 }
      ],
      dynamicList: [
        {
          name: "01.《不忘护士·战疫故事》正式发布，护联网为中国护士加油",
          id: 1,
          url: "https://static.hulian120.com/jiniance.pdf"
        },
        {
          name:
            "04.联合新华网发布《中国护士群体发展现状调查报告》推动护士职业发展",
          id: 2,
          url: "http://www.xinhuanet.com/gongyi/2017-05/11/c_129601419.htm"
        },
        {
          name: "02.守护战役天使，护航职业发展，护联网发布“天使护航计划”",
          id: 3,
          url: "https://mp.weixin.qq.com/s/W2XIoXVu69lg6dNSwhmIBQ"
        },
        {
          name:
            "05.护联网联合中国社会福利基金会发起中国首个以“关爱护士”为主题的公益基金-中国社会福利基金会护爱基金正式成立",
          id: 4,
          url:
            "http://www.cswef.org/index.php/cswef/welfarepro/detail/id/116.html"
        },
        {
          name:
            "03.护联网联合国家卫健委，在北京国家会议中心举办“2018护理创新发展论坛”",
          id: 5,
          url: "http://health.people.com.cn/n1/2018/0516/c14739-29994063.html"
        },
        {
          name:
            "06.发起“标准、安全、关爱由您践行”项目，全国485家医院参与此项目，推动静疗事业的发展。",
          id: 6,
          url: ""
        }
      ],
      partnerList: [
        require("./img/partner/partner1.png"),
        require("./img/partner/partner2.png"),
        require("./img/partner/partner3.png"),
        require("./img/partner/partner4.png"),
        require("./img/partner/partner5.png"),
        require("./img/partner/partner6.png"),
        require("./img/partner/partner7.png"),
        require("./img/partner/partner8.png"),
        require("./img/partner/partner9.png"),
        require("./img/partner/partner10.png"),
        require("./img/partner/partner11.png"),
        require("./img/partner/partner12.png"),
        require("./img/partner/partner13.png"),
        require("./img/partner/partner14.png"),
        require("./img/partner/partner15.png"),
        require("./img/partner/partner16.png")
      ],
      timer: null,
      count: 0,
      intervalTime: 5000,
      timequantum: 50,
      tabCurrent: 1,
      sliderNum: 0,
      symbol: "",
      flag: false,
      personFlag: false,
      dynamicCurrent: null,
      loveFundFlag: false,
      scrollTop: null
    };
  },
  methods: {
    //最新动态点击
    dynamicClick(item) {
      this.dynamicCurrent = item.id;
      if (item.url) {
        window.open(item.url);
      }
    },
    slideleft() {
      if (this.flag) {
        return;
      }
      let pList = document.querySelectorAll(".active_item");
      for (let i = 0; i < pList.length; i++) {
        pList[i].classList.remove("now_left");
        pList[i].classList.remove("next_left");
      }
      this.flag = true;
      let now = this.$refs["itemP" + this.sliderNum][0];
      let nextCount =
        this.sliderNum - 1 < 0
          ? this.sliderList.length - 1
          : this.sliderNum - 1;
      now.classList.add("now_left");
      let next = this.$refs["itemP" + nextCount][0];
      setTimeout(() => {
        next.classList.add("next_left");
        this.sliderNum = nextCount;
        this.flag = false;
      }, 700);
    },
    slideRight() {
      if (this.flag) {
        return;
      }
      let pList = document.querySelectorAll(".active_item");
      for (let i = 0; i < pList.length; i++) {
        pList[i].classList.remove("now_right");
        pList[i].classList.remove("next_right");
      }
      this.flag = true;
      let now = document.querySelector("#itemP" + this.sliderNum);
      let nextCount =
        this.sliderNum + 1 > this.sliderList.length - 1
          ? 0
          : this.sliderNum + 1;
      now.classList.add("now_right");
      let next = document.querySelector("#itemP" + nextCount);
      setTimeout(() => {
        next.classList.add("next_right");
        this.sliderNum = nextCount;
        this.flag = false;
      }, 700);
    },
    tabClick(id) {
      this.tabCurrent = id;
    },
    personHandleScroll() {
      if (!this.personFlag) {
        this.$refs.personNum.map(item => {
          item.accumulativeMachine();
        });
      }
      this.personFlag = true;
    },
    loveFundHandleScroll() {
      if (!this.loveFundFlag) {
        this.$refs.loveFundNum.map(item => {
          item.accumulativeMachine();
        });
      }
      this.loveFundFlag = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(()=>{
        window.addEventListener("scroll", () => {
          console.log(444)
          this.scrollTop =
              document.documentElement.scrollTop || document.body.scrollTop;
          if (isElementInViewport(this.$refs.person)) {
            if (!this.personFlag) {
              this.personHandleScroll();
            }
          }
          if (isElementInViewport(this.$refs.loveFund)) {
            if (!this.loveFundFlag) {
              this.loveFundHandleScroll();
            }
          }
        });

        let personTop = this.$refs.person.getBoundingClientRect().top;
        if (personTop >= 100) {
          this.personHandleScroll();
        }
      },800)
    });
  },
  beforeDestroy: function() {},
  components: {
    HomeCarousel,
    SmallWarm,
    LoveToBe,
    NurseLink,
    BackTop,
    CountNum
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.index {
  min-width: $--container-width;
  height: 100%;
  .content {
    .banner {
      width: 100%;
      display: block;
    }
    .list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 120px;
      .title {
        font-size: 40px;
        color: rgba(34, 34, 34, 1);
        display: flex;
        align-items: center;
        margin-top: 120px;
      }
      .line {
        width: 32px;
        height: 4px;
        background: rgba(213, 51, 27, 1);
        border-radius: 2px;
        margin: 24px 0 76px;
      }
      &.person {
        .title {
          margin-top: 78px;
        }
        .line {
          margin-bottom: 38px;
        }
        .list_content {
          width: $--container-width;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .li {
            width: 280px;
            height: 225px;
            background: rgba(255, 255, 255, 1);
            border-radius: 5px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: calc(56px * 0.8);
            &:nth-child(3n + 3) {
              margin-right: 0;
            }
            &:nth-child(-n + 3) {
              margin-bottom: calc(56px * 0.8);
            }
            .li_title {
              font-size: 42px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: rgba(213, 51, 27, 1);
            }
            .li_line {
              margin-top: 20px;
              width: 63px;
              height: 5px;
              background: rgba(230, 230, 230, 1);
              border-radius: 3px;
            }
            .name {
              margin-top: 20px;
            }
            img {
              margin-top: 20px;
              width: 28px;
              height: auto;
            }
          }
        }
      }
      &.product {
        background: #fcfcfc;
        .line {
          margin-bottom: 38px;
        }
        .list_content {
          width: $--container-width;
        }
        .tab {
          display: flex;
          justify-content: center;
          div {
            font-size: 24px;
            font-family: Source Han Sans CN;
            color: rgba(136, 136, 136, 1);
            margin-right: 178px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
            &.active {
              color: #d5331b;
            }
          }
        }
      }
      &.love_fund {
        position: relative;
        .title {
          color: #fff;
          margin-top: 154px;
        }
        .line {
          margin-bottom: 67px;
        }
        .data {
          width: 100%;
          height: 840px;
          background: url("./img/love_fund.png") no-repeat;
          background-size: cover;
          display: flex;
          justify-content: center;
          .data_content {
            width: $--container-width;
            .data_head {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .ul {
              width: 700px;
              height: 394px;
              background: rgba(255, 255, 255, 1);
              opacity: 0.98;
              border-radius: 5px;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              padding: 53px 64px;
            }
            .item {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin-right: 70px;
              &.li0 {
                margin-left: 17px;
              }
              &.li1 {
                margin-right: 80px;
              }
              &:nth-child(3n + 3) {
                margin-right: 0;
              }
              .li_title {
                font-size: 42px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: rgba(34, 34, 34, 1);
                margin-bottom: 24px;
                cursor: pointer;
                &:hover {
                  color: #d5331b;
                }
              }
              .name {
                font-size: 20px;
                font-family: Source Han Sans CN;
                color: rgba(85, 85, 85, 1);
              }
            }
          }
        }
        .more {
          width: 100%;
          background: url("./img/more.png") no-repeat;
          height: 120px;
          background-size: 100% 100%;
          .more_content {
            width: $--container-width;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            height: 100%;
            padding-left: 18px;
            align-items: center;
            .logo {
              display: flex;
              align-items: center;
              font-size: 20px;
              font-family: Source Han Sans CN;
              color: rgba(255, 255, 255, 1);
              img {
                margin-right: 20px;
                width: 184px;
                height: auto;
              }
            }
            a {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: rgba(213, 56, 32, 1);
              text-decoration: none;
              width: 124px;
              height: 38px;
              background: rgba(255, 255, 255, 1);
              border-radius: 19px;
              text-align: center;
              display: inline-block;
              line-height: 38px;
            }
          }
        }
      }
      &.dynamic {
        background: #fcfcfc;
        padding-bottom: 80px;
        box-sizing: border-box;
        .title {
          margin-top: 119px;
        }
        .list_content {
          width: $--container-width;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .li {
            width: calc(50% - 44px);
            margin-bottom: 53px;
            flex-shrink: 0;
            font-size: 20px;
            font-family: Source Han Sans CN;
            color: rgba(85, 85, 85, 1);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
            &:nth-child(2n + 2) {
              margin: 0;
            }
            &:nth-child(5),
            &:nth-child(6) {
              margin-bottom: 0;
            }
            &.active,
            &:hover {
              color: rgba(255, 68, 68, 1);
              text-decoration: underline;
            }
          }
        }
      }
      &.partner {
        padding-bottom: 78px;
        align-items: center;
        background: #f3f5f9;
        .title {
          margin-top: 78px;
        }
        .line {
          margin-bottom: 24px;
        }
        .list_content {
          display: flex;
          flex-wrap: wrap;
          width: 1030px;
          img {
            width: 234px;
            flex-shrink: 0;
            margin-right: 22px;
            margin-bottom: 22px;
            height: 130px;
            transition: box-shadow 0.2s;
            &:nth-child(4n + 4) {
              margin-right: 0;
            }
            &:hover {
              box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
            }
          }
        }
      }
    }
    .bear_plam {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 60px;
      .title {
        font-size: 40px;
        color: rgba(34, 34, 34, 1);
        display: flex;
        align-items: center;
        margin-top: 120px;
      }
      .line {
        width: 32px;
        height: 4px;
        background: rgba(213, 51, 27, 1);
        border-radius: 2px;
        margin: 24px 0 76px;
      }
      .list_content {
        width: $--container-width;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 28px;
          cursor: pointer;
          height: auto;
        }
        .center {
          margin: 0 105px;
          flex: 1;
          .item {
            background: rgba(255, 68, 68, 1);
            border-radius: 5px;
            .items-desktop {
              width: 100%;
              height: 305px;
              background: rgba(255, 68, 68, 1);
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                margin: 0;
                padding: 0;
                font-size: 36px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                backface-visibility: hidden;
                &.now_right {
                  animation: now_right 0.9s ease-in-out;
                }
                &.next_right {
                  animation: next_right 0.9s ease-in-out;
                }
                &.now_left {
                  animation: now_left 0.9s ease-in-out;
                }
                &.next_left {
                  animation: next_left 0.9s ease-in-out;
                }
              }
            }
          }
        }
      }
    }
  }
  @keyframes now_right {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(100px);
    }
  }
  @keyframes next_right {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes now_left {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-100px);
    }
  }
  @keyframes next_left {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
</style>
