<template>
  <div class="product_warm">
    <img src="../img/small_warm.png" class="small_warm" alt="" />
    <div class="right">
      <img src="../img/small_warm_logo.png" alt="" />
      <div class="text">
        <!-- 手机里的护理专家 <br />高年资护士上门服务<br />
        提供高品质居家护理服务<br />
        提高术后患者的康复质量 -->
        暖小加是专业的线上与线下一体化护理服务平台，以护士为核心，服务为根本，连接护士与用户的护理需求，让用户享受更有温度的护理服务，护士服务旨在提高用户的就医体验，改进护理服务模式，对用户医院就医，居家护理，慢病管理等提供了多方面支持，同时也拓展了护士人群的执业方向。
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.product_warm {
  display: flex;
  margin-top: 84px;
  .small_warm {
    width: 654px;
    height: 369px;
    flex-shrink: 0;
    object-fit: cover;
  }
  .right {
    margin-left: 66px;
    padding-top: 27px;
    img {
      width: 125px;
    }
    .text {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(136, 136, 136, 1);
      line-height: 36px;
      margin-top: 38px;
    }
  }
}
</style>
