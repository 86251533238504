<template>
  <span>{{ printVal }}</span>
</template>

<script>
export default {
  props: {
    startVal: {
      type: [String, Number],
      default: ""
    },
    endVal: {
      type: [String, Number],
      default: ""
    },
    speed: {
      type: [String, Number],
      default: 5
    },
    decimals: {
      type: [String, Number],
      default: 0
    },
    isReverse: {
      type: Boolean,
      default: false
    },
    numDecimals: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      start: +this.startVal,
      end: +this.endVal,
      formatSpeed: this.speed || 5,
      time: 10
    };
  },
  computed: {
    formatDecimals() {
      // 是否整数
      let formatDecimals = this.decimals > 0 ? this.decimals : 0;
      return formatDecimals;
    },
    decimalsLen() {
      let decimalsLen = Math.pow(10, this.formatDecimals);
      return decimalsLen;
    },
    printVal() {
      // 保留几位小数
      let start = (
        parseInt(this.start * this.decimalsLen) / this.decimalsLen
      ).toFixed(this.numDecimals);
      return start;
    }
  },
  watch: {},
  methods: {
    accumulativeMachine() {
      setInterval(() => {
        this.start += this.speed;
        if (this.time > 100 || this.start > this.end) {
          this.start = this.end;
          return;
        }
      }, 10);
    }
  },
  created() {}
};
</script>

<style scoped></style>
